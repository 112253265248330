import http from "@/utils/request";


// 商家查询待答问卷列表
/**
 * @param {
	 title
	 startDate yyyyMMdd
	 endDate yyyyMMdd
 } data
 */
export function getToBeAnsweredQuestion(data) {
	return http({
		method: "post",
		url: "/baseSurveyQuestion/getToBeAnsweredQuestion.nd",
		data: data
	});
}
