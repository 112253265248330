import navUtil from "@/components/global/CustomizeNav/util";
import {
	getToBeAnsweredQuestion
} from "./api";
export default {
	name: "questionnaire",
	data() {
		return {
			nolistImg: require("@/assets/order/noList.png"),
			breadcrumbData: [{
					path: "/index",
					name: "index",
					title: "首页"
				},
				{
					path: "/toBeAswered",
					name: "toBeAswered",
					title: "待答问卷"
				},
			],
			navList: [{
					title: "待答问卷",
					path: "/questionnaire/toBeAswered",
					active: true
				},
				{
					title: "已答问卷",
					path: "/questionnaire/answered",
					active: false
				},
			],
			title: '',
			startDate: '',
			endDate: '',
			date: [],
			questions: [],
			loading: false
		};
	},
	mounted() {
		this.getQuestions();
	},
	methods: {
		navCls(idx) {
			let cls;
			if (this.navList[idx].active) {
				cls = "active";
				if (idx == 0) {
					cls = "active active_one";
				}
			}
			if (
				idx < this.navList.length - 1 &&
				this.navList[Number(idx) + 1].active
			) {
				cls = "active-left";
			}
			if (idx > 0 && this.navList[Number(idx) - 1].active) {
				cls = "active-right";
			}
			return cls;
		},
		navClickFun(item) {
			if (item.path.length < 3) {
				this.$message.info("该模块正在开发");
				return;
			}
			if (item.active) {
				return;
			}
			for (const e of this.navList) {
				e.active = false;
			}

			item.active = true;
			this.$router.push({
				path: item.path
			});
		},
		dateChange(e) {
			this.startDate = this.getDate(e[0]._d);
			this.endDate = this.getDate(e[1]._d);
		},
		getDate(date, type) {
            if(date == '' || date == null || date == undefined) return '';
            date = date.replace(/-/g,'/');
			date = new Date(date);
			let year = date.getFullYear();
			let mon = date.getMonth() + 1;
			mon = mon < 10 ? '0' + mon : mon;
			let day = date.getDate();
			day = day < 10 ? '0' + day : day;
			if (type == 1) {
				return `${year}-${mon}-${day}`;
			}
			return `${year}${mon}${day}`;
		},
		toAnswer(id) {
			this.$router.push({
				path: '/questionnaire/answer',
				query: {
					questionId: id,
					account: this.$store.state.user.userInfo.account.account,
					source: 'WEBNEW'
				}
			});
		},
		getQuestions() {
			let data = {
				title: this.title,
				startDate: this.startDate,
				endDate: this.endDate
			};
			this.loading = true;
			let that = this;
			getToBeAnsweredQuestion(data).then(res => {
				if (res.data.code == 400 && res.data.msg == '登录过期，请重新登录') {
					this.$store.commit("user/logout", "");
					this.$notification.warning({
						message: "安全信息已过期，请重新登陆",
						duration: 3, //延时2.5s
						onClose: function() {
							that.$router.push("/login");
						},
					});
					return
				}
				let questions = res.data;
				for (let item of questions) {
					item.start = this.getDate(item.startDate, 1);
					item.end = this.getDate(item.endDate, 1);
				};
				this.questions = questions;
				this.loading = false;
			})
		},
		reset() {
			this.title = '';
			this.startDate = '';
			this.endDate = '';
			this.date = [];
			this.getQuestions();
		}
	}
};
